import { isLeapYear } from 'date-fns';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { useTitle } from '../../hooks/useTitle';
import { OrganizerRoleUtils, RoleUtils } from '../../types';
import { type Birthday, type WorkStartDate } from '../../types/celebration';
import { makeTitle } from '../../utils/common';
import { ChannelUtils } from '../Channel';
import { useUser } from '../UserContext';

export interface CelebrationPermission {
  inviteMembers: boolean;
  editable: boolean;
}

export function useCelebrationPermission(props: {
  channel: DtoChannel;
}): CelebrationPermission {
  const user = useUser();

  const isAdmin = RoleUtils.isAdmin(user);
  const isOrgOwnerOrAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  const isChannelCreator = user.id === props.channel.uid;

  return {
    inviteMembers: isChannelCreator,
    editable: isAdmin || isOrgOwnerOrAdmin || isChannelCreator,
  };
}

export function useCelebrationTitle(props: {
  tab: string;
  channel: DtoChannel;
}): void {
  useTitle(
    makeTitle(
      `Celebration ${props.tab} | ${ChannelUtils.ChannelName(props.channel)}`
    )
  );
}

export class CelebrationUtils {
  static ParseBirthday(birthday: Birthday | null): Date | null {
    if (!birthday) return null;

    const date = new Date();
    if (birthday.month === 2 && birthday.day === 29) {
      for (let i = 0; i < 4; i++) {
        if (isLeapYear(date)) {
          break;
        }
        date.setFullYear(date.getFullYear() - 1);
      }
    }
    date.setMonth(birthday.month - 1);
    date.setDate(birthday.day);
    return date;
  }
  static BirthdayFromDate(date: Date): Birthday {
    return { month: date.getMonth() + 1, day: date.getDate() };
  }
  static ParseWorkStartDate(workStartDate: WorkStartDate | null): Date | null {
    if (!workStartDate) return null;
    return new Date(
      workStartDate.year,
      workStartDate.month - 1,
      workStartDate.day
    );
  }
  static WorkStartDateFromDate(date: Date): WorkStartDate {
    return {
      year:
        date.getFullYear() < 100
          ? date.getFullYear() + 2000
          : date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
